const initialState = {
	biomarker: { open: true, status: 0 },
	error: '',
	guardia_advice: false,
	loading: false,
	modal: false,
	paginationTransport: 1,
	showAskText: true,
	nextPage: false,
	currentQuestion: 0,
	openDetails: false,
	output_classification: '',
	modalAction: false,
	mic_cam_permissions: '',
	remainingText: '',
	notificationsHome: {
		consultations: {new: false, quantity: 0},
		prescriptions: {new: false, quantity: 0}
	}
}

export default function frontReducers(state = initialState, action) {
	switch (action.type) {
		case 'LOADING':
			return { ...state, loading: action.payload }
		case 'CLOSE_MODAL':
			return { ...state, modal: false, action: action.action }
		case 'CURRENT_QUESTION':
			return { ...state, currentQuestion: ++state.currentQuestion }
		case 'TOGGLE_DETAIL':
			return { ...state, openDetails: !state.openDetails }
		case 'TOGGLE_MODAL_ACTION':
			return { ...state, modalAction: action.payload }
		case 'EDIT_SECTION':
			return { ...state, section: action.payload }
		case 'SET_APPOINT_ACTION':
			return { ...state, actionHandler: action.payload }
		case 'SET_NEXTPAGE':
			return { ...state, nextPage: action.payload }
		case 'REMAINING_ATT_TIME':
			return { ...state, remainingText: action.payload }
		case 'SET_CAM_MIC_PERMISSIONS':
			return { ...state, mic_cam_permissions: action.payload }
		case 'SET_GUARDIA_VARIABLES':
			return {...state, ...action.payload}
		case 'SET_GUARDIA_STATS':
			return {...state, ...action.payload}
		case 'SET_ORIGIN_TRANSLATE_MONDAY':
			return { ...state, origin_translate_monday: action.payload }
		case 'SET_ORIGIN_TRANSLATE_TUESDAY':
			return { ...state, origin_translate_tuesday: action.payload }
		case 'SET_ORIGIN_TRANSLATE_WEDNESDAY':
			return { ...state, origin_translate_wednesday: action.payload }
		case 'SET_ORIGIN_TRANSLATE_THURSDAY':
			return { ...state, origin_translate_thursday: action.payload }
		case 'SET_ORIGIN_TRANSLATE_FRIDAY':
			return { ...state, origin_translate_friday: action.payload }
		case 'SET_BACK_TRANSLATE_MONDAY':
			return { ...state, back_translate_monday: action.payload }
		case 'SET_BACK_TRANSLATE_TUESDAY':
			return { ...state, back_translate_tuesday: action.payload }
		case 'SET_BACK_TRANSLATE_WEDNESDAY':
			return { ...state, back_translate_wednesday: action.payload }
		case 'SET_BACK_TRANSLATE_THURSDAY':
			return { ...state, back_translate_thursday: action.payload }
		case 'SET_BACK_TRANSLATE_FRIDAY':
			return { ...state, back_translate_friday: action.payload }
		case 'OUTPUT_CLASSIFICATION':
			return { ...state, output_classification: action.payload }
		case 'NOTIFICATIONS_PRESCRIPTIONS':
			return { ...state, notificationsHome : { ...state.notificationsHome, prescriptions: action.payload}}	
		case 'NOTIFICATIONS_CONSULTATIONS':
			return { ...state, notificationsHome : { ...state.notificationsHome, consultations: action.payload}}	
		default:
			return state
	}
}