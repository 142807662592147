import React from 'react'
import coverageError from '@/assets/errorServer.svg'
import cancelAppointmentError from '@/assets/errorPopleCall.svg'
import confirmAppointmentError from '@/assets/errorConection.svg'
import downloadConstancyError from '@/assets/errorRobot.svg'
import limitAppointmentError from '@/assets/errorManLimit.svg'
import dniValildateError from '@/assets/errorValidateDni.svg'
import { IProblem } from './Status'
import Image from '@/reusableComponents/Image'
interface IInteractionIconProps {
	type: IProblem['type']
	typeError?: IProblem['typeError']
}

const icons = {
	'warning': <svg className="Canceled__ilustration" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="36" cy="36" r="36" fill="#F3B47D" />
		<path d="M36 13V44" stroke="#FEFEFE" strokeWidth="5" strokeLinecap="round" />
		<path d="M36 54L36 55" stroke="#FEFEFE" strokeWidth="5" strokeLinecap="round" />
	</svg>,
	'success': <svg className="Canceled__ilustration" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="36" cy="36" r="36" fill="#13C39C" />
		<path fillRule="evenodd" clipRule="evenodd" d="M52.2615 23.0916C53.2909 23.9151 53.4578 25.4171 52.6345 26.4466L35.3254 48.089C34.3177 49.3489 32.4717 49.5355 31.2324 48.5026L19.1148 38.4034C18.1013 37.5587 17.9644 36.0524 18.8091 35.0389C19.6536 34.0254 21.1599 33.8884 22.1735 34.7329L32.8017 43.5884L48.9064 23.4641C49.7301 22.4349 51.2321 22.2681 52.2615 23.0916Z" fill="white" />
	</svg>,
	coverage: coverageError,
	cancelAppointment: cancelAppointmentError,
	confirmAppointment: confirmAppointmentError,
	downloadConstancy: downloadConstancyError,
	limitAppointment: limitAppointmentError,
	serverError: coverageError,
	'validateDni-error': dniValildateError,
	'validateDni-badImage': dniValildateError,
	'validateDni-onlyImages': dniValildateError,
	'validateDni-uploadImgFailure': dniValildateError,
	'validateDni-notMatch': dniValildateError,
}

export default function InteractionIcon({ type, typeError }: IInteractionIconProps) {

	if (typeError?.length) {
		return <Image className='mx-auto' src={icons[typeError]} alt='' width='312px' center />
	}

	return (
		icons[type]
	)
}
