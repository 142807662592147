'use client'
import React, { useEffect } from 'react'
import version from '@/config/version.json'
import StackdriverErrorReporter from 'stackdriver-errors-js'
import Status from '@/components/GeneralComponents/Problem/Status'
import styles from '../OldRouter/App.module.scss'
import { Button, Spacer } from 'occipital-new'
import { is_server } from './endpoints'

let stackDriver : StackdriverErrorReporter|null = null
// https://github.com/GoogleCloudPlatform/stackdriver-errors-js -> Docu
try {
	if (typeof window !== 'undefined') {
		stackDriver = new StackdriverErrorReporter()
		if (process.env.NEXT_PUBLIC_FIREBASE_API_KEY && process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID){
			stackDriver.start({
				key: `${process.env.NEXT_PUBLIC_FIREBASE_API_KEY}`,
				projectId: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}`,
				service: `Patient-app-${process.env.NEXT_PUBLIC_COUNTRY}`,
				version: version.patients,
				// la api solicita los siguientes datos: 
				context: {
					reportLocation: {
						filePath: 'src/index.tsx',
						lineNumber: 0,
						functionName: 'index'
					}
				}
			})
		}
	}
} catch (err) {
	console.error(err)
}

export const errorHandler = is_server
	? {
		report: (message) => console.error(message),
	}
	: stackDriver

export interface IErrorFallbackProps {
	error: Error
	resetErrorBoundary: () => (void)
}

export function ErrorFallback({ error, resetErrorBoundary }: IErrorFallbackProps) {
	
	useEffect(() => {
		if (errorHandler){
			errorHandler.report(`[Hubo un error en la aplicación]: ${error?.name} ${error?.message} ${error.stack}`)
		}
	}, [])
	

	return (
		<div className={styles.App}>

			<Status
				type='warning'
				title='Hubo un error en la aplicación'
				subtitle={` error: ${error?.name} ${error?.message} ${error?.stack}, Podés comunicarte a info@uma-health.com`}
				report={false}
			>
				<>
					<Button
						action={() => resetErrorBoundary()}
						size="full"
						type='button'
						occ_type='filled'
					>
						Intentar nuevamente
					</Button>
					<Spacer direction="vertical" value="12px" />
					<Button
						action={() => location.href = '/'}
						size="full"
						occ_type='outlined'
						type='button'
					>
						Volver al inicio
					</Button>
				</>
			</Status>
		</div>
	)
}
