export const typeErrors = {
	coverage: {
		title: 'No pudimos cargar tu cobertura',
		subtitle: 'Parece que ocurrió un problema al cargar tu cobertura de salud. Por favor, intentá nuevamente.',
		iconName: 'coverage',
	},
	changeCoverge: {
		title: 'No logramos modificar los datos',
		subtitle: 'Tus datos no pudieron modificarse debido a un problema. Por favor, intentá nuevamente.',
		iconName: 'coverage'
	},
	cancelAppointment: {
		title:'Error al cancelar turno',
		subtitle: 'No pudimos cancelar tu turno en este momento. Por favor, inténtalo de nuevo.',
		iconName: 'cancelAppointment'
	},
	confirmAppointment : {
		title:'Turno no confirmado aún',
		subtitle: 'Ocurrió un problema y no pudimos confirmar el turno. Por favor, intentá nuevamente.',
		iconName: 'confirmAppointment'
	},
	downloadConstancy: {
		title: 'No se pudo descargar la constancia',
		subtitle: 'Ocurrió un problema al descargar la constancia. Por favor, intentá nuevamente.',
		iconName: 'downloadConstancy',
	},
	downloadResume: {
		title: 'No se pudo descargar el resumen',
		subtitle: 'Ocurrió un problema al descargar el resumen de atención. Por favor, intentá nuevamente.',
		iconName: 'downloadConstancy',
	},
	limitAppointment: {
		title: 'Superaste tu límite de consultas',
		subtitle: 'Comunicate con tu obra social para contar con más consultas disponibles.',
		iconName: 'limitAppointment',
		showError: false,
	},
	serverError: {
		title: 'Error en el servidor',
		subtitle: 'Ocurrió un problema en nuestro servidor, estamos trabajando en solucionarlo, puede comunicarse a info@uma-health.com',
		iconName: 'limitAppointment'
	},
	guardiaTimeout: {
		title: 'Restricción temporal de guardia',
		subtitle: '',
		iconName: 'limitAppointment'
	},
	'validateDni-error': {
		title: 'Ocurrió un error en la validación',
		subtitle:'No pudimos validar correctamente tu documento. \nTe pedimos si podes volver a repetirlo.',
		iconName: 'validateDni-error'
	},
	'validateDni-badImage': {
		title: 'Ocurrió un error en la validación',
		subtitle: 'La imagen que adjuntaste posee calidad baja para poder ser validada. \nTe pedimos si podes volver a repetirlo. ',
		iconName: 'validateDni-badImage'
	},
	'validateDni-onlyImages' : {
		title: 'Solo se permiten archivos de imagen',
		subtitle: 'Los formatos permitidos son JPG, JPEG o PNG. \nTe pedimos si podes volver a repetirlo. ',
		iconName: 'validateDni-onlyImages'
	},
	'validateDni-notMatch': {
		title: 'Ocurrió un error en la validación',
		subtitle: 'El documento adjunto no corresponde a la persona titular de la cuenta.',
		iconName: 'validateDni-notMatch'
	},
	'validateDni-uploadImgFailure' : {
		title: 'Ocurrió un error en la validación',
		subtitle: 'No pudimos subir la imagen. \nTe pedimos si podes volver a repetirlo. ',
		iconName: 'validateDni-uploadImgFailure'
	}
} as const
