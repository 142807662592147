import  NextImage from 'next/image'
import { IImage } from './IImage'

/**
 * Parsea un string que representa un número seguido de 'px' en un número.
 *
 * @param {string} str - El string a ser parseado, por ejemplo, '128px'.
 * @returns {number} El número parseado.
 * @throws {Error} Si el string no tiene el formato correcto.
 */
function parsearPixelString(str: string): number|null {

	if (str === 'auto'){
		return null
	}

	// Verificar que el string termine con 'px'
	if (!str.endsWith('px')) {
		return null
	}

	// Extraer la parte numérica del string y convertirla a número
	const numeroStr = str.slice(0, -2) // Elimina las dos últimas letras ('px')
	const numero = parseFloat(numeroStr)

	// Verificar si el resultado es un número válido
	if (isNaN(numero)) {
		throw new Error('No se pudo parsear el número correctamente.')
	}

	return numero
}

function Image ( { src, alt, width, height, rounded, className, ...tags} : IImage){
	const widthParsed = typeof width === 'string' ? parsearPixelString(width) : width 
	const heightParsed = typeof height === 'string'? parsearPixelString(height) : height 


	if (typeof src === 'string'){
		return <NextImage 
			className={`${rounded?'rounded-full':''} ${className ? className : ' w-auto h-auto'}`} 
			src={src} 
			alt={alt} 
			width={widthParsed??20} 
			height={heightParsed??20}
			{...tags} />
	}

	return <NextImage 
		className={`${rounded?'rounded-full':''} ${className ? className : ' w-auto h-auto'}`} 
		src={src.src} 
		alt={alt} 
		width={widthParsed?? src.width ?? 20} 
		height={heightParsed?? src.height ?? 20} 
	/>
}

export default Image