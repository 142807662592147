import { ActionAssessment } from './actions'
import { IAssessmentState } from './state'

const initialAssessmentState: IAssessmentState = {
	appointment: '',
	answers: '',
	answersId: [],
	biomarkers: null,
	currentQuestion: {
		id: '',
		input: 'select',
		title: '',
		answers: [],
		dominio: '',
	},
	files: [],
	selectedSymptoms: [],
	selectedQuestions: [],
	symptomsForDoc: [],
}


export default function assessmentReducers(state = initialAssessmentState, action : ActionAssessment ) {
	switch (action.type) {
		case 'SET_SELECTED_QUESTIONS':
			return {
				...state,
				'selectedQuestions': action.payload
			}
		case 'SET_CURRENT_QUESTION':
			return {
				...state,
				'currentQuestion': action.payload
			}
		case 'SAVE_ANSWERS':
			return {
				...state,
				'answers': state.answers.concat(action.payload)
			}
		case 'SAVE_FILES':
			return {
				...state,
				'files': [...state.files, action.payload]
			}
		case 'SAVE_ANSWERS_ID':
			return {
				...state,
				'answersId': action.payload
			}
		case 'SET_ASSESSMENT_BIOMARKER':
			return {
				...state,
				'biomarkers': {...state.biomarkers, ...action.payload}
			}
		default:
			return state
	}
}