const initialState = {
	cardioTest:{
		age: '',
		diabetic: '',
		familyHeartAttack: '',
		heartAttack: '',
		hypertensive: '',
		sex: '',
		smoker: '',
		scoreCardio: '',
	},
	diabetesTest:{
		age: '',
		sex: '',
		fisicalActivity: '',
		familyDiabetes: '',
		hypertensive: '',
		height: '',
		weight: '',
		scoreDiabetes: '',
	},
	modelsOutput:{
		outputDiabetes: '',
		outputCardio: '',
		probabilityDiabetes: '',
		probabilityCardio: '',
	},
	research: {
		consent: '',
		research: '',
		type: '',
		providerUid: ''
	}
}
export default function cardioReducers(state = initialState, action) { 
	switch (action.type) { 
		case 'CARDIO_TEST_FILL':
			return { ...state, cardioTest: action.payload }
		case 'DIABETES_TEST_FILL':
			return { ...state, diabetesTest: action.payload }
		case 'MODELS_OUTPUT':
			return { ...state, modelsOutput: action.payload }
		case 'CARDIO_RESEARCH':
			return { ...state, research: action.payload }
		case 'CARDIO_RESEARCH_CONSENT':
			return { ...state, ...state.research, consent: action.payload }
		default:
			return state
	}  
}
