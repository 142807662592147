import { Patient } from '@umahealth/entities'
import { ActionUser } from './actions'
import { IUserState } from './state'

const initialState : IUserState = {
	... new Patient(),
	os: '',
	osNumber: 0,
	recovery: [],
	profile_pic: '',
	newValues: {
		login: [],
		email: '',
		fullname: '',
		firstname: '',
		lastname: '',
		dni: '',
		ws: '',
		sex: '',
		dob: '',
		corporate: '',
		country: '',
		nationality: '',
		accept: {
			date: '',
			terms_version: 1,
			privacy_version: 1,
		},
		registerCompleated: true,
	}
}

/**
 *
 * @param state state 
 * @param action actions
 * @returns reducer
 */
export default function userReducers(
	state = initialState,
	action : ActionUser) : IUserState {
	switch (action.type) {
		case 'GET_PATIENT':
			return {...state, ...action.payload }
		case 'USER_ACTIVE_APPOINTMENT':
			return {...state, active_appointment: action.payload }
		case 'USER_FIRST_SEX':
			return { ...state, sex: action.payload }
		case 'USER_FIRST_DNI':
			return { ...state, dni: action.payload }
		case 'USER_FIRST_DOB':
			return { ...state, dob: action.payload }
		case 'USER_FIRST_COUNTRY':
			return { ...state, country: action.payload }
		case 'USER_FIRST_WS':
			return { ...state, ws: action.payload }
		case 'USER_FIRST_OS':
			return { ...state, os: action.payload }
		case 'USER_FIRST_OS_NUMBER':
			return { ...state, osNumber: action.payload }
		case 'USER_FIRST_FULLNAME':
			return { ...state, fullname: action.payload }
		case 'RESET_USER_DATA':
			return initialState
		case 'USER_RECOVERY_DATA':
			return {...state, recovery: action.payload}
		default:
			return state
	}
}
