import { combineReducers } from 'redux'
import assessment from './assessment/assessmentReducers'
import autonomous from './autonomousReducers'
import cardiovascular from '../../components/RiesgoCardiovascular/store/cardioReducers'
import deliveryService from './deliveryReducers'
import front from './frontReducers'
import inPersonService from './inPersonServiceReducers'
import payments from './payments'
import queries from './queries/queriesReducers'
import services from './servicesReducers'
import user from './user/userReducers'

const appReducer = combineReducers({
	/** Sólo se usa en SelectReason */
	assessment,
	/** Se usa en 5 archivos */
	autonomous,
	/** Se usa en 5 archivos */
	cardiovascular,
	/** Se usa en 34 archivos, 50 usos */
	deliveryService,
	/** Se usa en 13 archivos */
	front,
	/** Se usa en 5 archivos */
	inPersonService,
	/** Se usa en 10 archivos */
	payments,
	/* Se usa en 52 archivos */
	user,
	/* Se usa en 3 archivos */
	queries,
	/* Se usa en 13 archivos */
	services,
	
})

/** Aproximadamente hay que tocar 161 archivos para eliminar redux */

const rootReducer = (state, action) => {
	if (action.type === 'RESET_ALL') {
		state = { 
			queries: state.queries, 
			deliveryService: state.deliveryService,
			user: state.user,
			assessment: state.assessment,
			autonomous: state.autonomous,
			cardiovascular: state.cardiovascular,
			cx: state.cx
		}
	}
	return appReducer(state, action)
}

export default rootReducer
