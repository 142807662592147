import * as types from '../types/paymentsTypes'

const initialState = {
	patientUid: '',
	clients: [],
	corporate: '',
	dependant: '',
	dependantUid: '',
	description:'',
	discount_code: 'no_discount',
	dni: '',
	doc_id: '',
	full_price: '',
	fullname: '',
	id: '',
	mercadoPago: false,
	method: '',
	paymentId: '',
	price: '',
	product: '',
	providerToken: '',
	provider_cuit: '',
	provider_uid: '',
	quantity: 1,
	service: '',
	specialty: '',
	subscription: '',
	subscriptionStock: 0,
	title: '',
	uid: '',
}
    
export default function PaymentsReducer (state = initialState, { type, payload }) {
	switch (type) {
		case types.SET_PAYMENT:
			return {...state, quantity: payload.quantity, product: payload.product, title: payload.title, abbottId: payload.abbottId, uid: payload.uid, service: payload.service, dependant: payload.dependant, price: payload.price, fakePrice: payload.fakePrice, mercadoPago: payload.mercadoPago, description: payload.description, corporate: payload.corporate, dni: payload.dni, fullname: payload.fullname, patientUid: payload.patientUid, providerToken: payload.providerToken, provider_uid: payload.provider_uid, provider_cuit: payload.provider_cuit, doc_id: payload.doc_id, discount_code: payload.discount_code, clients: payload.clients, id: payload.id, dependantUid: payload.dependantUid, specialty: payload.specialty, full_price: payload.full_price }
		case types.RESET_PAYMENT:
			return {...state,dependant: '', description:'', abbottId: '', mercadoPago: false, price: '', product: '', quantity: 1, service: '', title: '',uid: '', corporate: '', dni: '', fullname: '', patientUid: '', providerToken: '', provider_uid: '', provider_cuit: '', discount_code: 'no_discount', clients: [], id: '', dependantUid: '', method: '', paymentId: '', specialty: '', full_price: '', subscription: '', subscriptionStock: 0 }
		case types.SET_PAYMENT_ID:
			return {
				...state,
				paymentId: payload.paymentId,
				method: payload.method
			}
		case types.SET_SUBSCRIPTION:
			return {
				...state,
				subscription: payload.subscription,
				subscriptionStock: payload.subscriptionStock
			}
		default:
			return state
	}
}