import { GuardiaAppointment } from '@umahealth/entities'
import { ActionAppointment } from './actions'
import { IQueriesState } from './state'

/*
 * autonomousData: se usa cuando vemos el historial de Autonomous nada más, se podría eliminar
 * beneficiaries: Se usa en MyRecods y History.js, similar a autonomousData creo
 * medicalRecord: Se usa en MyRecords, Rating, History.js y EventHistory.index.js, se usa a través de getMedicalRecord de FirebaseQueries
 * prescriptions: Se usa en PrescriptionList a través de FirebaseQueries
 * plan: Se usa, En Auth y en WhoScreen se carga.
 * questions: Se cargan en SelectReason.
 * Symptoms: Se carga en SelectReason.
 * assignedAppointment: Se usa en todos lados, se carga en queue, NotificationDelay, WhoScreen
 */
const initialState: IQueriesState = {
	autonomousData: [],
	beneficiaries: [],
	medicalRecord: [],
	prescriptions: [],
	plan: {
		onlinedoctor: {
			ambulance: '0',
			autonomous: '0',
			constancy: '0',
			home_care: '0',
			lab_orders: '0',
			my_specialist: '0',
			onlinedoctor: '0',
			pol: '0',
			recipe: '0',
			translation: '0',
			umacare: '0',
			vmd: '0',
			wellness: '0',
		},
		plan: {},
	},
	questions: [],
	symptoms: [],
	assignedAppointment: { 
		...new GuardiaAppointment(),
		path: ''
	},
	voucher: []
}

/**
 * 
 * @param state state of queriesReducers
 * @param action action of queriesReducers
 * @returns state
 */
function queriesReducers(
	state = initialState,
	action: ActionAppointment
) : IQueriesState {
	switch (action.type) {
		case 'GET_QUESTIONS':
			return {...state, questions: action.payload}
		case 'GET_SYMPTOMS':
			return {...state, symptoms: action.payload}
		case 'GET_BENEFICIARIES':
			return {...state, beneficiaries: action.payload}
		case 'GET_MEDICAL_RECORD':
			return {...state, medicalRecord: action.payload}
		case 'SET_PLAN_DATA':
			return {...state, plan: action.payload}
		case 'SET_ASSIGNED_APPOINTMENT':
			return {...state, assignedAppointment: action.payload}
		case 'SET_PRESCRIPTIONS':
			return {...state, prescriptions: action.payload}
		default:
			return state
	}
}

export default queriesReducers