import * as service from '../types/servicesTypes.ts'

const initialState = {
	deliveryCoverage: {},
	currentServices: {
		delivery: [],
		onSite: [],
	}
}

export default function servicesReducers (state = initialState, { type, payload }) {
	switch (type) {
		case service.SET_NEW_DELIVERY_SERVICE:
			return { ...state, currentServices: {...state.currentServices, delivery: [...state.currentServices.delivery, payload]}}
		default:
			return state
	}
}